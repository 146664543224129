/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import tw, { css, theme } from "twin.macro";
import PropTypes from "prop-types";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";

const FilterSelect = ({
  allFilters,
  excluded,
  onClick,
  primary,
  setTip,
  setTipActive,
  tip,
  title,
  values,
  widget
}) => {
  const { isDesktop, screen } = useContext(DocumentContext);

  const filters = allFilters[title];

  let rows;

  switch (title) {
    case `color`: {
      const upperRow = [];
      const middleRow = [];
      const lowerRow = [];

      if (screen !== `xs`) {
        values.forEach((value) => {
          if (value.toLowerCase() === `any` || value.length === 1) {
            upperRow.push(value);
          } else {
            lowerRow.push(value);
          }
        });

        rows = [upperRow, lowerRow];
      } else {
        // values.sort(
        values.forEach((value) => {
          switch (value.toLowerCase()) {
            case `any`:
              upperRow.push(value);
              break;

            case `fancy blue`:
            case `fancy yellow`:
            case `fancy pink`:
            case `all fancy colors`:
              lowerRow.push(value);
              break;

            default:
              middleRow.push(value);
              break;
          }
        });

        rows = [upperRow, middleRow, lowerRow];
      }

      break;
    }

    case `shape`: {
      const upperRow = [];
      const middleRow = [];
      const lowerRow = [];

      if (screen !== `xs`) {
        values.forEach((value, valueIndex) => {
          if (!widget) {
            middleRow.push(value);
            rows = [middleRow];
          } else {
            if (valueIndex % 2 === 0) {
              upperRow.push(value);
            } else {
              lowerRow.push(value);
            }

            rows = [upperRow, lowerRow];
          }
        });
      } else {
        values.forEach((value, valueIndex) => {
          switch (valueIndex % 3) {
            case 0:
              upperRow.push(value);
              break;

            case 1:
              middleRow.push(value);
              break;

            case 2:
              lowerRow.push(value);
              break;

            default:
              break;
          }
        });

        rows = [upperRow, middleRow, lowerRow];
      }

      break;
    }

    case `clarity`: {
      if (screen !== `xs`) {
        rows = [values];
      } else {
        const upperRow = [];
        const middleRow = [];
        const lowerRow = [];

        values.forEach((value) => {
          switch (value.toUpperCase()) {
            case `SALT AND PEPPER`:
            case `SI2`:
            case `SI1`:
              upperRow.push(value);
              break;

            case `VS2`:
            case `VS1`:
            case `VVS2`:
              middleRow.push(value);
              break;

            case `VVS1`:
            case `IF`:
            case `FL`:
              lowerRow.push(value);
              break;

            default:
              lowerRow.push(value);
              break;
          }
        });

        rows = [upperRow, middleRow, lowerRow];
      }

      break;
    }

    case `cut`: {
      if (screen !== `xs`) {
        rows = [values];
      } else {
        const upperRow = [];
        const middleRow = [];
        const lowerRow = [];

        values.forEach((value) => {
          switch (value.toLowerCase()) {
            case `all`:
              upperRow.push(value);
              break;

            case `good`:
            case `very good`:
              middleRow.push(value);
              break;

            case `ideal`:
            case `super ideal`:
              lowerRow.push(value);
              break;

            default:
              lowerRow.push(value);
              break;
          }
        });

        rows = [upperRow, middleRow, lowerRow];
      }

      break;
    }

    case `fluorescence`: {
      if (screen !== `xs`) {
        rows = [values];
      } else {
        const upperRow = [];
        const middleRow = [];
        const lowerRow = [];

        values.forEach((value) => {
          switch (value.toLowerCase()) {
            case `any`:
            case `none`:
              upperRow.push(value);
              break;

            case `faint`:
            case `medium`:
              middleRow.push(value);
              break;

            case `strong`:
            case `very strong`:
              lowerRow.push(value);
              break;

            default:
              lowerRow.push(value);
              break;
          }
        });

        rows = [upperRow, middleRow, lowerRow];
      }

      break;
    }

    case `grading lab`: {
      if (screen !== `xs`) {
        rows = [values];
      } else {
        const upperRow = [];
        const lowerRow = [];

        values.forEach((value) => {
          switch (value.toUpperCase()) {
            case `ALL`:
            case `GIA`:
              upperRow.push(value);
              break;

            case `GCAL`:
            case `IGI`:
              lowerRow.push(value);
              break;

            default:
              lowerRow.push(value);
              break;
          }
        });

        rows = [upperRow, lowerRow];
      }

      break;
    }

    case `supplier standards`: {
      if (screen !== `xs`) {
        rows = [values];
      } else {
        const upperRow = [];
        const lowerRow = [];

        values.forEach((value) => {
          switch (value.toLowerCase()) {
            case `level 1`:
            case `level 2`:
              upperRow.push(value);
              break;

            case `level 3`:
            case `level 4`:
              lowerRow.push(value);
              break;

            default:
              lowerRow.push(value);
              break;
          }
        });

        rows = [upperRow, lowerRow];
      }

      break;
    }

    case `type`: {
      if (screen !== `xs`) {
        rows = [values];
      } else {
        const upperRow = [];
        const middleRow = [];
        const lowerRow = [];

        values.forEach((value) => {
          switch (value.toLowerCase()) {
            case `natural diamond`:
              upperRow.push(value);
              break;

            case `lab diamond`:
              middleRow.push(value);
              break;

            case `moissanite`:
              lowerRow.push(value);
              break;

            default:
              lowerRow.push(value);
              break;
          }
        });

        rows = [upperRow, middleRow, lowerRow];
      }

      break;
    }

    default:
      rows = [values];
      break;
  }

  const borderColor = widget ? theme`colors.gray` : theme`colors.silver`;

  //

  return (
    <article
      css={[
        css`
          padding-top: ${widget ? `0.75rem` : `1.5rem`};
          padding-bottom: ${widget ? `0.75rem` : `2rem`};
        `,
        tw`w-full relative block text-white`
      ]}
    >
      {!widget && isDesktop() && (
        <div css={[tw`h-5 relative flex items-center mb-3`]}>
          {tip && (
            <button
              type="button"
              css={[tw`w-5 h-5 absolute top-0 left-0 block -ml-6`]}
              onClick={() => {
                setTip({ ...tip, title });
                setTipActive(true);
              }}
            >
              <div tw="w-full h-full flex items-center justify-center">
                <Icon.Tip styles={[tw`h-3`]} />
              </div>
            </button>
          )}

          <T.Heading font="b2" level="3" styles={[tw`text-white uppercase`]}>
            <span>{title}</span>
          </T.Heading>
        </div>
      )}

      {rows?.[0] &&
        rows.map((rowValues, rowIndex) => {
          const key = `filter-select-row-${rowIndex}`;

          return (
            <ul
              key={key}
              css={[
                css`
                  transition: opacity 0.15s ${A.EASING_CUBIC};

                  opacity: ${excluded ? `0.25` : `1`};

                  ${excluded && `pointer-events: none !important;`}
                `,
                tw`relative flex flex-row flex-wrap`
              ]}
            >
              {rowValues.map((value, valueIndex) => {
                const valueStr = value?.name || value;
                const selected = filters?.[0] && filters.includes(valueStr);

                let overrideStr;

                if (valueStr.toLowerCase() === `old european` && !isDesktop()) {
                  overrideStr = `Old Euro`;
                }

                //

                let height = `3rem`;

                if (primary) {
                  height = `3.5rem`;
                } else if (value?.name) {
                  height = `auto`;
                }

                return (
                  <li
                    key={`${title}-${valueStr}`}
                    css={[
                      css`
                        height: ${height};
                        flex: 1;
                      `,
                      tw`block`
                    ]}
                  >
                    <button
                      css={[
                        css`
                          transition: background 0.3s ${A.EASING_CUBIC};

                          background: ${selected
                            ? theme`colors.white`
                            : `transparent`};
                          color: ${selected
                            ? theme`colors.black`
                            : theme`colors.white`};

                          border-top: 1px solid
                            ${title.toLowerCase() !== `shape`
                              ? borderColor
                              : `transparent`};
                          border-right: 1px solid
                            ${title.toLowerCase() !== `shape`
                              ? borderColor
                              : `transparent`};
                          border-bottom: 1px solid
                            ${title.toLowerCase() !== `shape`
                              ? borderColor
                              : `transparent`};
                          border-left: 1px solid
                            ${title.toLowerCase() !== `shape` &&
                            valueIndex === 0
                              ? borderColor
                              : `transparent`};

                          &:focus {
                            outline: 0;
                          }
                        `,
                        tw`w-full h-full relative block`
                      ]}
                      type="button"
                      onClick={() => onClick({ key: title, value: valueStr })}
                    >
                      <div
                        css={[
                          css`
                            text-transform: ${primary ? `uppercase` : `none`};
                          `,
                          tw`w-full h-full relative flex items-center justify-center px-1 sm:px-0`
                        ]}
                      >
                        {(value?.icon && (
                          <div
                            css={[
                              tw`relative flex flex-col items-center mt-2 mb-2`
                            ]}
                          >
                            {value.icon}

                            <T.Caption styles={[tw`mt-2`]}>
                              {overrideStr || valueStr}
                            </T.Caption>
                          </div>
                        )) || (
                          <T.Heading
                            font={primary ? `5` : `b2`}
                            serif={primary}
                          >
                            {value}
                          </T.Heading>
                        )}
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
          );
        })}
    </article>
  );
};

export default FilterSelect;

FilterSelect.defaultProps = {
  onClick: () => {}
};

FilterSelect.propTypes = {
  onClick: PropTypes.func
};
